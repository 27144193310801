import { Heading, Paragraph, Grid } from '@byteclaw/visage';
import { GetStaticProps } from 'next';
import React from 'react';

import { Layout, SEO, ArticleCard, ArticleCardData } from '../components';
import { serializeMdx } from '../helpers/serializeMdx';
import {
  NotFoundPageArticlesDocument,
  NotFoundPageArticlesQuery,
} from '../queries.graphql';
import { graphqlClient, imageLoader } from '../services';

interface NotFoundPageProps {
  categories: NotFoundPageArticlesQuery['categories'];
  newestArticles: ArticleCardData[];
}

const NotFoundPage = ({ categories, newestArticles }: NotFoundPageProps) => {
  return (
    <Layout categories={categories}>
      <SEO path="/" title="Stránka sa nenašla" />
      <Heading styles={{ fontSize: 4, lineHeight: '1.1em', mb: '1rem' }}>
        Stránka sa nenašla
      </Heading>
      <Paragraph>
        Je nám ľúto ale stránka neexistuje. No nezúfajte a prečítajte si jeden z
        najnovších článkov.
      </Paragraph>
      <Grid
        styles={{
          gridTemplateColumns: ['1fr', 'repeat(3, minmax(0, 1fr))'],
          gridColumnGap: [0, '1rem'],
          gridRowGap: '2rem',
          gridTemplateRows: 'auto',
          my: 4,
        }}
      >
        {newestArticles.map((article) => (
          <ArticleCard key={article.id} article={article} />
        ))}
      </Grid>
    </Layout>
  );
};

export const getStaticProps: GetStaticProps<NotFoundPageProps> =
  async function getStaticProps() {
    const articles = await graphqlClient.request<NotFoundPageArticlesQuery>(
      NotFoundPageArticlesDocument,
    );

    const newestArticles = await Promise.all(
      articles.newestArticles.map(async ({ content, ...article }) => {
        const mdxSource = await serializeMdx(content, { imageLoader });

        return {
          ...article,
          timeToRead: mdxSource.readTimes,
        };
      }),
    );

    return {
      props: {
        categories: articles.categories,
        newestArticles,
      },
    };
  };

export default NotFoundPage;
